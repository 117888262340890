import React from 'react';
import { Images } from '../../Themes';

const CareManager = () => {
  return (
    <div style={{ textAlignLast: 'center', marginTop: '-64px' }}>
      <img width="100%" src={Images.wello.care_manager} alt="care_manager" />
    </div>
  );
};

export default CareManager;
